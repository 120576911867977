import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin.js";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";

interface Point {
  x: number
  y: number
}

type Highlightbox = Point & {
  width: number
  height: number
  highlight: 'before' | 'after'
}

interface LineSegment {
  directive: string
  coordinates: Point[]
}

const calculatePull = (x1: number, x2: number, factor: number): number => {
  return x1 + (x2 - x1) * factor
}
const curvePointBetween = (p1: Point, p2: Point, factor = 2 / 3): Point => {
  // We need to calculate the "pull" direction of the curve:
  // 1. If the line goes from left to right the pull direction should be to the right.
  // 2. If the line goes from right to left the pull direction should be to the left.
  // 3. If the line goes from top to bottom the pull direction should be to the top.
  // 4. If the line goes from bottom to top the pull direction should be to the bottom.
  const pullX = p1.x < p2.x ? calculatePull(p1.x, p2.x, factor) : calculatePull(p2.x, p1.x, factor)
  const pullY = p1.y < p2.y ? calculatePull(p1.y, p2.y, factor) : calculatePull(p2.y, p1.y, factor)
  return { x: pullX, y: pullY }
}

const generatePath = (points: Point[]): string => {
  const lineSegments: LineSegment[] = points.map((currentPoint, pointIndex, thePoints) => {
    switch (pointIndex) {
      case 0:
        return { directive: 'M', coordinates: [currentPoint] }
      case 1: {
        const firstPoint: Point = thePoints[pointIndex - 1]
        const virtualPointOne: Point = curvePointBetween(firstPoint, currentPoint, 1 / 3)
        const virtualPointTwo: Point = curvePointBetween(firstPoint, currentPoint, 2 / 3)
        return { directive: 'C', coordinates: [virtualPointOne, virtualPointTwo, currentPoint] }
      }
      default: {
        const previousPoint: Point = thePoints[pointIndex - 1]
        const virtualPoint: Point = curvePointBetween(previousPoint, currentPoint)
        return { directive: 'S', coordinates: [virtualPoint, currentPoint] }
      }
    }
  })
  const path = lineSegments.map(lineSegment => {
    const coordinateString = lineSegment.coordinates.map(coordinate => {
      return `${coordinate.x} ${coordinate.y}`
    }).join(', ')
    return `${lineSegment.directive} ${coordinateString}`
  }).join(' ')
  return path
}

/*
const createCircles = (points: Point[]): SVGCircleElement[] => {
// <circle class="circle follower" r="5" cx="50" cy="100" fill="#BBD8B8"></circle
// <circle class="circle circle-waypoint circle01" r="5" cx="278" cy="201" fill="#BBD8B8"></circle
// <circle class="circle circle-waypoint circle02" r="5" cx="327" cy="401" fill="#BBD8B8"></circle
// <circle class="circle circle-waypoint circle03" r="5" cx="203" cy="601" fill="#BBD8B8"></circle
  return points.map((point) => {
    const circleElement = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
    circleElement.setAttribute('r', '5')
    circleElement.setAttribute('cx', `${point.x}`)
    circleElement.setAttribute('cy', `${point.y}`)
    circleElement.setAttribute('fill', 'red')
    return circleElement
  })
}
*/

const isWithinBox = (point: Point, box: Highlightbox, distanceToElement: number): boolean => {
  const totalBuffer = distanceToElement
  return point.x >= box.x - totalBuffer && point.x <= (box.x + box.width + totalBuffer) &&
        point.y >= box.y - totalBuffer && point.y <= (box.y + box.height + totalBuffer)
}

const findClosestPositionOnLine = (highlightBox: Highlightbox, line: SVGPathElement, distanceToElement: number): Point => {
  const totalLength = line.getTotalLength()

  let lastPositionCandidate = null

  if (highlightBox.highlight === 'before') {
    for (let i = 0; i < totalLength; i = i + distanceToElement / 2) {
      const positionCandidate = line.getPointAtLength(i)
      if (isWithinBox(positionCandidate, highlightBox, distanceToElement)) {
        return lastPositionCandidate
      } else {
        lastPositionCandidate = positionCandidate
      }
    }
  } else {
    for (let i = totalLength; i > 0; i = i - distanceToElement / 2) {
      const positionCandidate = line.getPointAtLength(i)
      if (isWithinBox(positionCandidate, highlightBox, distanceToElement)) {
        return lastPositionCandidate
      } else {
        lastPositionCandidate = positionCandidate
      }
    }
  }
}

const createCirclesOnPath = (highlightElements: Highlightbox[], line: SVGPathElement): SVGCircleElement[] => {
  const distanceToElement = 50

  return highlightElements.map((highlightBox) => {
    const pointPosition = findClosestPositionOnLine(highlightBox, line, distanceToElement)

    const circleElement = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
    circleElement.setAttribute('r', '5')
    circleElement.setAttribute('cx', `${pointPosition.x}`)
    circleElement.setAttribute('cy', `${pointPosition.y}`)
    circleElement.setAttribute('fill', '#BBD8B8')
    circleElement.setAttribute('class', 'strength-point-of-interest')
    return circleElement
  })
}

const createFollower = (coordinates: Point): SVGCircleElement => {
  const follower = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
  follower.setAttribute('r', '5')
  follower.setAttribute('cx', `${coordinates.x}`)
  follower.setAttribute('cy', `${coordinates.y}`)
  follower.setAttribute('fill', '#BBD8B8')
  follower.setAttribute('class', 'follower')
  return follower
}

const createLine = (coordinates: Point[]): SVGPathElement => {
  // We need to use the SVG namespace to create SVG elements to trigger a redraw in the browser
  const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  pathElement.setAttribute('fill', 'transparent')
  pathElement.setAttribute('d', generatePath(coordinates))
  pathElement.setAttribute('class', 'mainline')
  return pathElement
}

const clearSnake = (elID: string): void => {
  document.querySelector('' + elID).childNodes.forEach((node) => {
    node.remove()
  });
  // remove points, follower and mainline
  ['.strength-point-of-interest', '.follower', '.mainline'].forEach((className: string): void => {
    [...document.querySelectorAll(className)].forEach(item => {
      item.remove()
    })
  })
}

const debounce = (func: Function, wait: number = 100) => {
  let timer
  return function (event) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func, wait, event)
  }
}

const anchorClassNameToPointMap: Record<string, (HTMLElement) => Point> = {
  'snake-anchor-top-left': (element) => { return { x: element.offsetLeft, y: element.offsetTop }},
  'snake-anchor-top-left-outside': (element) => { return { x: element.offsetLeft-10, y: element.offsetTop }},
  'snake-anchor-top-right': (element) => { return { x: element.offsetLeft + element.offsetWidth, y: element.offsetTop }},
  'snake-anchor-top-center': (element) => { return { x: element.offsetLeft + element.offsetWidth / 2, y: element.offsetTop }},
  'snake-anchor-bottom-left-outside': (element) => { return { x: element.offsetLeft-10, y: element.offsetTop + element.offsetHeight }},
  'snake-anchor-bottom-right': (element) => { return { x: element.offsetLeft + element.offsetWidth, y: element.offsetTop + element.offsetHeight }},
  'snake-anchor-bottom-center': (element) => { return { x: element.offsetLeft + element.offsetWidth / 2, y: element.offsetTop + element.offsetHeight }},
  'snake-anchor-center': (element) => { return { x: element.offsetLeft + element.offsetWidth / 2, y: element.offsetTop + element.offsetHeight / 2 }}
}

const getCoordinates = (element: HTMLElement, isMobile = false): Point => {
  const defaultPositionClassName = 'snake-anchor-bottom-left';
  const cssClasses = element.classList;
  let positionDirective: string = defaultPositionClassName;

  if (isMobile) {
    cssClasses.forEach((value, key, parent) => {
      if (value.startsWith('mobile-snake-anchor-')) {
        positionDirective = value.replace('mobile-', '');
      }
    })
  }
  else {
    cssClasses.forEach((value, key, parent) => {
      if (value.startsWith('snake-anchor-')) {
        positionDirective = value;
      }
    })
  }
  return anchorClassNameToPointMap[positionDirective](element)
      ?? anchorClassNameToPointMap[defaultPositionClassName](element);

}

const getHighlightBox = (element: HTMLElement): Highlightbox => {
  const cssClasses = element.classList
  return {
    x: element.offsetLeft,
    y: element.offsetTop,
    width: element.offsetWidth,
    height: element.offsetHeight,
    highlight: cssClasses.contains('snake-highlight-after') ? 'after' : 'before'
  }
}

const getCirclePositions = (circles: SVGCircleElement[]): number[] => {
  return circles.map(circle => {
    return circle.getBBox().y
  })
}

const registerGsapEvents = (circles: SVGCircleElement[], snake: SVGPathElement, isMobile = false): void => {
  gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, MotionPathPlugin)

  gsap.defaults({ ease: 'none' })

  const timelineDuration = 5

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: '#snake',
      scrub: true,
      start: 'top 80%',
      end: 'bottom bottom'
    }
  })
    .to('.follower', { autoAlpha: 1, duration: timelineDuration })
    .from('.mainline', { drawSVG: 0, duration: timelineDuration }, 0)
    .to('.follower', { motionPath: { path: '.mainline', align: '.mainline', alignOrigin: [0.5, 0.5] }, duration: timelineDuration }, 0);

  if (!isMobile) {
    const pulses = gsap.timeline({
      defaults: {
        opacity: 1,
        scale: 2,
        autoAlpha: 1,
        transformOrigin: 'center',
        ease: 'bounce(2.5, 1)'
      }
    })

    const circlePositions = getCirclePositions(circles)

    circles.forEach((circle, idx) => {
      const uMax = timelineDuration / snake.getBBox().height
      const uCur = (circlePositions[idx]) * uMax
      pulses.to(circle, {}, uCur - 0.1)
    })

    timeline.add(pulses, 0);
  }
}

const isBelowMobileBreakpoint = () => {
  const breakpointString = window.getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-mobile-landscape');
  const breakpoint = parseInt(breakpointString, 10);
  const viewPortWidth = window.innerWidth;
  return viewPortWidth <= breakpoint;
}

const drawSnake = (elID: string) => {
  const isMobile = isBelowMobileBreakpoint();

  const coordinates = [...document.querySelectorAll('.snake-point')].map((item): Point => {
    return getCoordinates(item as HTMLElement, isMobile)
  })

  // createCircles(coordinates).forEach((circle) => {
  //     document.querySelector(''+ elID).appendChild(circle)
  // });
  const snake = createLine(coordinates)

  let circles: SVGCircleElement[] = [];
  if (!isMobile) {
    const highlightBoxes = [...document.querySelectorAll('.snake-point.snake-highlight')].map((item): Highlightbox => {
      return getHighlightBox(item as HTMLElement)
    })
    circles = createCirclesOnPath(highlightBoxes, snake)
    circles.forEach((circle) => {
      document.querySelector('' + elID).appendChild(circle)
    })
  }

  document.querySelector('' + elID).appendChild(snake)
  document.querySelector('' + elID).appendChild(createFollower(coordinates[0]))
  registerGsapEvents(circles, snake, isMobile)
}

const registerSnakeEventListeners = (): void => {
  const elId = '#snake';
  const snakeElementCandidate = document.querySelector(elId);

  if (snakeElementCandidate !== null) {
    window.addEventListener('resize', debounce(() => { clearSnake(elId); drawSnake(elId) }))
    window.addEventListener('load', debounce(() => { clearSnake(elId); drawSnake(elId) }))
  }
}

registerSnakeEventListeners()
