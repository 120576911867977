interface Offset {
  top: number
  left: number
  width: number
  height: number
}

// Get the offset of an element
const getOffset = (el): Offset => {
  let _x = 0
  let _y = 0
  const bound = el.getBoundingClientRect()
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft
    _y += el.offsetTop - el.scrollTop
    el = el.offsetParent
  }
  return { top: _y, left: _x, width: bound.width, height: bound.height }
}

// Place all kidneys to elements with class "niere" and callback
const placeNieren = (callback): void => {
  const markupDynamicElements = []
  const elementsShouldHaveNieren = document.getElementsByClassName('niere')

  for (let i = 0; i < elementsShouldHaveNieren.length; i++) {
    const elementInProgress = elementsShouldHaveNieren[i]
    const rect = getOffset(elementInProgress)
    const topPosition = rect.top + (rect.height - 70)
    const leftPosition = rect.left - 50
    markupDynamicElements.push(`
            <div class="pos" style="z-index: -100; position: absolute; border: 1px solid transparent; top: ${topPosition}px; left: ${leftPosition}px;">
            
            <adt-components-morph-gsap
                    time="80"
                    waittime="40000"
                    strokecolor="#5A9854"
                    strokewidth="1rem"
                    d1="M511.844 231.25c47.162 5.169 92.549 61.533 64.088 149.265-47.943 147.782-163.635 194.902-205.006 126.797-4.262-7.016-16.828-28.534-30.789-50.763-39.232-53.446-109.625-57.33-138.806-56.431a436.773 436.773 0 0 1-13.234.89l.002-.003c-36.651 1.853-71.604-2.203-94.265-30.823-46.878-58.455 20.91-219.241 107.529-269.928 26.083-15.337 48.655-21.739 68.48-21.739 53.848 0 87.482 47.203 116.518 90.818 34.103 51.984 65.387 54.397 125.483 61.917Z"
                    d2="M241.34-39.489c260.248-85.433 313.538-11.48 334.846 326.353 14.163 224.554-151.452 120.197-499.496 119.18-297.892-.871-117.285-352.98 164.65-445.533Z"
                    transform="matrix(.506 0 0 .506 88.192 100.051)"
            />
            
            </div>
        `)
  }

  callback(markupDynamicElements.join('\n'))
}

// Place all dynamic elements
const placeElements = function (): void {
  const dynamicElementsContainer = document.getElementById('dynamic-elements')
  dynamicElementsContainer.innerHTML = ''

  const addElements = function (markup: string): void {
    dynamicElementsContainer.innerHTML = dynamicElementsContainer.innerHTML + markup
  }

  placeNieren(addElements)
}

window.onresize = placeElements
window.onload = placeElements

