
import { _ } from 'lodash';

/// Get the slug from the current page
/// that is everything behind the hash: https://example.com/page#i-am-the-slug = i-am-the-slug
function getSlug(){
    const slug = document.location.hash.replace(/^\#/, '')
    return slug.length > 0 ? slug : null
}

/// Get the page url from the currently opend page without any query parameters and hashes
function getCurrentPageUrl(){
    return window.location.href.split(/[?#]/)[0];
}

/// Atteched a click event listener to an `Anchor` element
/// @see Anchor
function attachClickListener(element: Element){
    element.addEventListener("click", function() {
        this.classList.toggle("active");
        const content = this.nextElementSibling;
        if (content.style.maxHeight){
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    });
}

function attachClipboardListener(element: Element) {
    element.addEventListener("click", ()=> {
        navigator.clipboard.writeText( getCurrentPageUrl() +'#'+ element.dataset.slug );
        element.classList.add("animate");
        setTimeout(()=>{
            element.classList.remove("animate");
        }, 800);
    });
}

function openDesiredElement(elementAnchors: [Anchor]){
    const slug = getSlug();
    let elementToOpen = _.find(elementAnchors, {slug: slug})?.element ?? _.first(elementAnchors).element
    elementToOpen.click();
}

/// An Anchor is slug and a corresponding Element
/// Attention: Slugs are **not** unique!
interface Anchor {
    slug: string
    element: Element
}

/// Open and Close collapsible tabs
window.addEventListener('DOMContentLoaded', () => {
    // only on page /workshops
    if(window.location.pathname.indexOf('workshops') === -1) { return }

    const collapsibles = document.getElementsByClassName("collapsible");
    const copyables = document.getElementsByClassName("copyable")

    // Mapping `Elements` in `Anchors`
    const elementAnchors: [Anchor] = _.map(collapsibles, (elm)=>{
        return {
            slug: _.first(elm.parentElement.getElementsByTagName('a')).id,
            element: elm
        }
    });
    // Click to open
    _.each(elementAnchors, (elm)=>{
        attachClickListener(elm.element);
    });
    // Click to copy
    _.each(copyables, (elm)=>{
        attachClipboardListener(elm);
    });

    openDesiredElement(elementAnchors)
});
