window.addEventListener('load', () => {
  const langLinks = document.getElementsByClassName('language')
  for(let i = 0; i < langLinks.length; ++i){
    let lang = langLinks[i].getAttribute("data-lang")

    langLinks[i].addEventListener('click', (ev)=>{
      document.location.href = document.location.href
          .replace(document.location.search, "")
          + `?lang=${lang}`;
      document.cookie = `lang=${lang}`
      console.log(`Set language: ${lang} = ${document.location.href}`)
    })
  }
})
